import { Controller } from "stimulus"
import Highcharts from 'highcharts';

export default class extends Controller {
  static targets = [ "chartData" ]

  connect() {
    const employeeNames = this.chartDataTarget.dataset.employeenames;
    const printedPrints = this.chartDataTarget.dataset.printed;
    const scannedPrints = this.chartDataTarget.dataset.scanned;
    const scrappedPrints = this.chartDataTarget.dataset.scrapped;
    const averagePrintedPrintsByDay = this.chartDataTarget.dataset.average;

    Highcharts.setOptions({
      lang: {
        thousandsSep: ','
      }
    });

    const chart = Highcharts.chart('employee-bar-chart', {
      colors: ['#058DC7', '#CCCCCC', '#50B432', '#ED561B'],
      title: {
        text: 'Employee Stats Today',
        x: -150 //center
      },
      xAxis: {
        categories: JSON.parse(employeeNames)
      },
      yAxis: {
        title: {
          text: 'Print Count'
        },
        plotLines: [{
          value: 0,
          width: 1,
          color: '#808080'
        }]
      },
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:,.0f}</b><br/>',
        shared: true
      },
      legend: {
        layout: 'horizontal',
        align: 'right',
        x: 0,
        verticalAlign: 'top',
        y: 0,
        floating: true,
      },
      series: [
        {
          name: 'Printed Count',
          type: 'column',
          data: JSON.parse(printedPrints),
        },
        {
          name: 'Scanned Count',
          type: 'column',
          data: JSON.parse(scannedPrints),
        },
        {
          name: 'Failed Count',
          type: 'column',
          data: JSON.parse(scrappedPrints),
        },
        {
          name: 'Average Prints Printed',
          type: 'spline',
          zIndex: -1,
          marker: { enabled: false },
          dashStyle: 'shortdot',
          data: JSON.parse(averagePrintedPrintsByDay)
        },
      ]
    });
  };
}
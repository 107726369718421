import { Controller } from "stimulus";
import debounce from "debounce";

export default class extends Controller {
  initialize() {
    this.submit = debounce(this.submit.bind(this), 1000);
  }

  submit(_event) {
    this.element.requestSubmit();
  }
}
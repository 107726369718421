import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.maxQuantity = document.getElementById("max-fail-count").innerText
  }

  addFailReason(event){
    event.preventDefault()
    const first_fail_reason = document.getElementById("quality-form").querySelector(".fail-reason:first-child")

    this.createFailReason(first_fail_reason)
    this.updateQuantities(this.maxQuantity)
    this.updateButtonDisplay(this.maxQuantity)
  }

  removeFailReason(event){
     event.preventDefault()
     const last_fail_reason = document.getElementById("quality-form").querySelector(".fail-reason:last-child")
     last_fail_reason.parentElement.removeChild(last_fail_reason)

     this.updateQuantities(this.maxQuantity)
     this.updateButtonDisplay(this.maxQuantity)
  }

  createFailReason(element){
    const fail_reason_count = document.getElementById("quality-form").querySelectorAll(".fail-reason").length
    const new_fail_reason = element.cloneNode(true)
    const new_count = fail_reason_count + 1

    new_fail_reason.setAttribute("id", `fail-reason-${new_count}`)

    new_fail_reason.querySelectorAll("#internal_fail_reason_1").forEach(element => {
      element.setAttribute("name", `internal_fail_reason_${new_count}`)
      element.setAttribute("id", `internal_fail_reason_${new_count}`)
    });

    new_fail_reason.querySelectorAll("#external_fail_reason_1").forEach(element => {
      element.setAttribute("name", `external_fail_reason_${new_count}`)
      element.setAttribute("id", `external_fail_reason_${new_count}`)
    });

    new_fail_reason.querySelectorAll("#quantity_1")[0].setAttribute("name", `quantity_${new_count}`)
    new_fail_reason.querySelectorAll("#quantity_1")[0].setAttribute("id", `quantity_${new_count}`)
    new_fail_reason.querySelectorAll("#printed_1")[0].setAttribute("name", `printed_${new_count}`)
    new_fail_reason.querySelectorAll("#printed_1")[0].setAttribute("id", `printed_${new_count}`)

    document.getElementById("quality-form").appendChild(new_fail_reason)
  }

  updateButtonDisplay(quantity_count){
    const fail_reason_count = document.getElementById("quality-form").querySelectorAll(".fail-reason").length

    if (fail_reason_count > 1) {
      document.getElementById("remove-fail-reason-button").style.display = "block"
    } else {
      document.getElementById("remove-fail-reason-button").style.display = "none"
    }

    if (fail_reason_count < quantity_count) {
      document.getElementById("add-fail-reason-button").style.display = "block"
    } else{
      document.getElementById("add-fail-reason-button").style.display = "none"
    }
  }

  updateQuantities(maxQuantity){
    const quantity_selects = document.getElementById("quality-form").querySelectorAll(".quantity-select")
    const fail_reason_count = document.getElementById("quality-form").querySelectorAll(".fail-reason").length - 1

    quantity_selects.forEach((quantity_select, index) => {
      const possibleFailCount = maxQuantity - fail_reason_count;
      quantity_select.value = ""
      quantity_select.max = possibleFailCount
    })
  }
}
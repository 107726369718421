import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
  }

  toggleMenu(event){
    event.preventDefault()
    var body_header = document.getElementById("body-header")
    body_header.classList.toggle("kt-aside--on")

    var button_toggler = document.getElementById("kt_aside_mobile_toggler")
    button_toggler.classList.toggle("kt-header-mobile__toolbar-toggler--active")

    

    var aside_menu = document.getElementById("kt_aside")
    aside_menu.classList.toggle("kt-aside--on")
  }

  toggleUserMenu(event){
    event.preventDefault()
    var body_header = document.getElementById("body-header")
    body_header.classList.toggle("kt-header__topbar--mobile-on")

    var button_toggler = document.getElementById("kt_header_mobile_topbar_toggler")
    button_toggler.classList.toggle("kt-header-mobile__toolbar-topbar-toggler--active")
  }

  toggleAsideMenu(event){
    event.preventDefault()

    var aside_menu_toggler = document.getElementById("kt_aside_toggler")
    aside_menu_toggler.classList.toggle("kt-aside__brand-aside-toggler--active")

    var body = document.body
    body.classList.toggle("kt-aside--minimize")
  }

}
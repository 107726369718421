import { Controller } from "stimulus"

export default class extends Controller {
  static values = { url: String, refreshInterval: Number }
  static targets = [ "statContainer" ]

  connect() {
    this.load();

    if (this.hasRefreshIntervalValue) {
      this.startRefreshing()
    }
  };

  disconnect() {
    this.stopRefreshing()
  };

  load(){
    fetch(this.urlValue)
      .then(response => response.text())
      .then(html => this.element.innerHTML = html)
  }

  startRefreshing() {
    this.employeeStats = setInterval(() => {
      this.load()
    }, this.refreshIntervalValue)
  }

  stopRefreshing() {
    if (this.employeeStats) {
      clearInterval(this.employeeStats)
    }
  }

}

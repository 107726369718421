import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
  }

  dropdownChange(event) {
    const select = event.target
    this.selectedAccount = select.options[select.selectedIndex].value
    this.fetchData("location", select.value)
  }

  fetchData(item, value, name="name"){
    const items = `${item}s`
    const path = `/${items}?account_id=${value}`

    fetch(path)
      .then(response => response.json())
      .then(data => { this.createOptionsFor(item, data, name)} )
  }

  createOptionsFor(id, data, name) {
    const select = document.getElementById(id)
    select.innerHTML = ""
    data.forEach(item => {
      const option = document.createElement("option")
      option.value = item.id
      option.innerText = item[name]
      select.appendChild(option)
    })
  }

  triggerChange(element){
    let changeEvent = new Event('change');
    element.dispatchEvent(changeEvent);
  }
}
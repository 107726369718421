import { Controller } from "stimulus"

export default class extends Controller {
  static values = { refreshInterval: Number }
  static targets = [ "timeContainer", "timeClock" ]

  connect() {
    this.load();

    if (this.hasRefreshIntervalValue) {
      this.startRefreshing()
    }
  };

  disconnect() {
    this.stopRefreshing()
  };

  load(){
    const last_login = this.timeContainerTarget.dataset.lastlogin * 1000;
    const time_now = Date.now();
    
    var timeSinceLastLogin = new Date(time_now - last_login);
    var hourSince = timeSinceLastLogin.getUTCHours();
    var minSince  = timeSinceLastLogin.getMinutes();
    var secSince  = timeSinceLastLogin.getSeconds();

    if (this.hasTimeClockTarget) {
      this.timeClockTarget.innerHTML = addZero(hourSince)+":"+addZero(minSince)+":"+addZero(secSince);
    }

    function addZero(n) { return n < 10 ? ('0' + n) : n };
  }

  startRefreshing() {
    this.machineTimer = setInterval(() => {
      this.load()
    }, this.refreshIntervalValue)
  }

  stopRefreshing() {
    if (this.machineTimer) {
      clearInterval(this.machineTimer)
    }
  }

}

import { Controller } from "stimulus"
import Highcharts from 'highcharts';

export default class extends Controller {
  static targets = [ "chartData" ]

  connect() {
    const printedPrintsByDay = this.chartDataTarget.dataset.printed;
    const receivedPrintsByDay = this.chartDataTarget.dataset.received;
    const averagePrintedPrintsByDay = this.chartDataTarget.dataset.average;

    Highcharts.setOptions({
      lang: {
        thousandsSep: ','
      }
    });

    const chart = Highcharts.chart('production-stat-chart', {
      colors: ['#058DC7', '#50B432', '#ED561B'],
      title: { 
        text: 'Production by Day',
        x: -150 //center
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          day: '%b %e'
        }
      },
      yAxis: {
        title: { text: 'Print Count' },
        plotLines: [{
          value: 0,
          width: 1,
          color: '#808080'
        }]
      },
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:,.0f}</b><br/>',
        shared: true
      },
      legend: {
        layout: 'horizontal',
        align: 'right',
        x: 0,
        verticalAlign: 'top',
        y: 0,
        floating: true,
      },
      series: [
        {
          name: 'Prints Received',
          type: 'column',
          stacking: 'normal',
          data: JSON.parse(receivedPrintsByDay)
        },
        {
          name: 'Prints Printed',
          type: 'column',
          data: JSON.parse(printedPrintsByDay)
        },
        {
          name: 'Average Prints Printed',
          type: 'spline',
          zIndex: -1,
          marker: { enabled: false },
          dashStyle: 'shortdot',
          data: JSON.parse(averagePrintedPrintsByDay)
        },
      ]
    });
  };
}